@import '../variables.scss';

footer {

  .footerContent {
    margin: 40px min(4.5vw, 120px) 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: $primary-font-color;

    .footerTopRow {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .footerLogo {

      }

      .footerSocial {

      }

      .footerLink {

      }

      @media only screen and (max-width: 900px) {
        flex-direction: column;
        justify-content: space-between;

        .footerLogo {
          padding-bottom: 32px;
        }

        .footerSocial {
          padding-bottom: 24px;
        }

        .footerLink {
          padding-bottom: 16px;
        }
      }
    }

    a {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.015em;
    }

    .footerLine {
      display: block;
      margin: 30px auto 24px;
      width: 100%;
      height: 1px;
      padding: 0;
      background-color: #E9E9E9;
    }

    .footerBottomRow {
      font-size: 14px;
      font-weight: 500;
      line-height: 23px;
      letter-spacing: -0.005em;
      text-align: center;
    }
  }

  .socialLink {
    width: 32px;
    height: 32px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
