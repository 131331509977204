@import '../variables.scss';

.policyContainer {
  background-color: $base-color;
  border-radius: 5px;
  padding: 20px;
  margin-top: 24px;
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.policyCard {
  display: flex;
  align-items: flex-start;
  padding: 24px;
  background-color: #F5F5F5; /* Light grey background for unselected cards */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;

  &:hover {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  }
}

.selected {
  border: 2px solid #2E5B42;
  box-shadow: 0 3px 10px rgba(46, 91, 66, 0.15);
  background-color: #FFFFFF; /* White background for selected cards */

  .policyRate {
    font-weight: 700; /* Bold font weight for selected policy rate */
  }
}

.radioCircle {
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #5E5E5E;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-top: 2px;
}

.radioInner {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #2E5B42;
}

.policyContent {
  flex: 1;
}

.policyTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 22px;
  color: #4A4A4A;
  margin-bottom: 8px;
}

.policyRate {
  font-size: 20px;
  font-weight: 500;
  color: #4A4A4A;
  margin-bottom: 4px;
}

.policyDetail {
  font-size: 18px;
  color: #5E5E5E;
  margin-top: 4px;
}

.hiddenRadio {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* Responsive adjustments */
@media only screen and (max-width: 460px) {
  .policyCard {
    padding: 16px;
  }

  .policyTitle {
    font-size: 22px;
  }

  .policyRate {
    font-size: 18px;
  }

  .policyDetail {
    font-size: 15px;
  }}
