@import '../variables.scss';

@mixin commentCommon {
  text-align: left;
  font-family: 'Quattrocento Sans', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.005em;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 7px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.payment {
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  color: #5E5E5E;
}

.section {
  background-color: $base-color;

  .content {
    padding-top: 60px;
    margin-left: auto;
    margin-right: auto;
    width: 35%;

    @media only screen and (max-width: 1200px) {
      width: 45%;
    }

    @media only screen and (max-width: 940px) {
      width: 55%;
    }

    @media only screen and (max-width: 765px) {
      width: 65%;
    }

    @media only screen and (max-width: 650px) {
      width: 85%;
    }

    fieldset {
      border: unset;
      margin-top: 24px;
      margin-bottom: 22px;

      &.light {
        background: #FFFFFF;
        border-radius: 5px;
        padding: 20px 20px 4px; // 4 on bottom
      }
    }

    h1 {
      color: $primary-font-color;
      font-size: 38px;
      font-weight: 500;
      line-height: 51px;
      letter-spacing: -0.005em;
      text-align: center;

      @media only screen and (max-width: 1024px) {
        font-size: 32px;
        line-height: 38px;
      }
    }

    h4 {
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      line-height: 38px;
      letter-spacing: -0.005em;
    }

    .paymentNotice {
      text-align: center;
      font-size: 20px;
    }

    .label {
      color: $primary-font-color;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.005em;
    }

    .comment {
      @include commentCommon;
      color: #5E5E5E;
    }

    .error {
      @include commentCommon;
      color: #CB3535;
    }

    .topRow {
      margin-top: 24px;
    }

    .fieldRow {
      margin-bottom: 16px;
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
      gap: 14px;

      @media only screen and (max-width: 650px) {
        flex-direction: column;
      }

      .field {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .quarterField {
        width: 50%;
      }

      .twoThirdsField {
        flex: 2 1 auto;
      }

      .oneThirdField {
        flex: 1 1 auto;
      }

      .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 4px;
      }

      .radioField {
        display: flex;
        flex-direction: column;
        justify-content: center; /* Centers radio buttons vertically */
      }

      .radioGroup {
        display: flex;
        gap: 20px;
        margin-top: 7px;
      }

      .radioGroup label {
        display: flex;
        align-items: center; /* Centers the label text with the larger radio button */
      }
    }

    .purchasePrice {
      white-space: nowrap;
      display: inline-block;
      width: 100%;
      padding-right: 14px;
    }

    .symbol {
      font-weight: normal;
      font-size: 20px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: #5A5A5A;
      padding-right: 4px;
    }

    .buttonRow {
      margin-top: 40px;
      padding-bottom: 60px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
    }

    .totalRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 42.5px
    }

    .totalItem {
      width: 50%;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      label {
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0;
        padding-right: 20px;
      }

      .price {
        font-weight: 500;
        font-size: 26px;
        line-height: 31px;
        color: #5E5E5E;
      }
    }

    .acknowledgementRow {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 15px;

      input {
        margin-top: 3px;
      }

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        letter-spacing: -0.005em;
        color: #545454;
      }
    }
  }
}

@mixin inputCommon {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-indent: 8px;
}

input[type="text"], input[type="tel"], select {
  @include inputCommon;
  width: 100%;
  height: 35px;
  background: #FFFFFF;
  border: 1px solid rgba(196, 196, 196, 0.5);
  box-sizing: border-box;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

input[type="checkbox"] {
  min-width: 20px
}

@mixin placeholderCommon {
  color: $secondary-font-color;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  @include inputCommon;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  @include inputCommon;
}

::-ms-input-placeholder { /* Microsoft Edge */
  @include inputCommon;
}

.dateOfBirth {
  width: 100%;
  height: 35px;
  text-align: left;
  color: #545454;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;

  background: #FFFFFF;
  border: 1px solid rgba(196, 196, 196, 0.5);
  box-sizing: border-box;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

textarea {
  text-indent: 8px;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}

.editButton {
  border: unset;
  background-color: unset;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;

  &:hover {
    cursor: pointer;
  }
}

.openFormButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  gap: 16px !important;

  @media only screen and (min-width: 650px) {
    transform: translateY(10px);
    justify-content: flex-end !important;
  }
}

.customDatePicker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.addCattleRow {
  margin-top: 24px;
  background-color: white;
  width: 100%;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
}

.addCattleBtn {
  border: unset;
  background-color: unset;
  color: #2E5B42;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;

  svg {
    margin-right: 8px;
  }
}

.animalRollup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 5px;
  margin-bottom: 24px;

  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
  }

  .rollupValue {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #4A4A4A;
  }

  .actionBtns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    align-items: flex-start;
    svg {
      margin-right: 4px;
    }
  }
}
