@import './variables.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Zilla Slab', serif;
}

input[disabled],button[disabled]  {
  /* Fix Safari disabled text */
  -webkit-text-fill-color: black;
  opacity: 0.65;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}
