@import '../variables.scss';

.header {
  width: 100%;
  background-color: $base-color;

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: max(8px, min(2vw, 20px)) max(2vw, min(6vw, 8.125vw)) 6px;
    flex-wrap: nowrap;

    img {
      margin-top: 20px;
      margin-bottom: 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.headerHome {
  background-color: unset;
}
