@import '../variables.scss';

@mixin buttonCommon {
    height: 43px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:hover {
        cursor: pointer;
    }
}

.buttonHeaderHome {
    @include buttonCommon;
    color: $green;
    border: 2px solid $green;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(4px);
}

.buttonClear {
    @include buttonCommon;
    color: $green;
    border: 2px solid $green;
    background: transparent;
    //backdrop-filter: blur(4px);
}

.buttonFilled {
    @include buttonCommon;
    color: white;
    border: 2px solid #2E5B42;
    background: #2E5B42;
}

.buttonGetStarted {
    @include buttonCommon;
    font-size: 20px;
    color: #2E5B42;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);

    @media only screen and (max-width: 740px) {
        width: 160px;
    }
}

.externalLinkAlt::after {
    font-family: 'Font Awesome 5 Free';
    font-size: 14px;
    content: '\f35d';
    padding-left: 4px;
    color: #59795E;
}

.terms {
    padding-bottom: 40px;

    @media only screen and (min-width: 1024px) {
        width: 50% !important;
    }

    h1 {
        text-align: center;
    }

    p {
        padding-top: 24px;

        span {
            font-weight: 800;
        }
    }
}

.privacy {
    padding-bottom: 40px;

    @media only screen and (min-width: 1024px) {
        width: 50% !important;
    }

    h1 {
        line-height: 45px !important;
        padding-bottom: 24px;

        @media only screen and (max-width: 1024px) {
            font-size: 32px !important;
            line-height: 38px !important;
        }

        @media only screen and (max-width: 425px) {
            font-size: 28px !important;
            line-height: 30px !important;
        }
    }

    h2 {
        padding-top: 1rem;
        padding-bottom: 0.2rem;
        line-height: 1.75rem;
        font-size: 1.5rem;
    }

    p {
        padding-top: 0;
        //padding-bottom: 1px !important;
    }
}

.contact {
    padding-bottom: 40px;

    h1 {
        //line-height: 32px !important;
    }

    .contactButtonRow {
        padding-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }

    fieldset {
        margin-bottom: unset !important;
    }

    .callUs {
        background-color: white;
        border-radius: 5px;
        margin-top: 20px;
        padding: 20px;

        h2 {
            text-align: center;
            font-size: 28px;
            font-weight: 500;
            line-height: 1em;
            padding-bottom: 16px;
        }

        .callUsRow {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 24px;
            align-content: space-between;

            div{
                font-size: 20px;
                line-height: 28px;
                color: $primary-font-color;
                width: 50%;

                a:link, a:visited {
                    text-decoration: inherit;
                    color: $primary-font-color;
                }

                a::before {
                    font-family: 'Font Awesome 5 Free';
                    font-size: 14px;
                    content: '\f879';
                    padding-right: 4px;
                    color: $green;
                }

            }

        }
    }
}
